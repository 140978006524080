<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑     永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2022-09-21 16:15:46
 * @LastEditTime: 2022-09-21 17:49:10
 * @Description: 数据字典
 * @FilePath: \BusinessPlatform\src\views\admin\dataDictionary\index.vue
 -->

<template>
    <div class="dataDictionary">
        <ul class="dataDictionaryNav">
            <template v-for="(item, index) in navData">
                <li
                    :class="{'active': selectNavIndex === index}"
                    :key="`dataDictionaryNav_${index}`"
                    @click="selectNav(index, item)"
                >
                    <i class="iconfont" :class="item.iconFont"></i>
                    <span>{{ item.name }}</span>
                </li>
            </template>
        </ul>
        <div class="dataDictionaryContent">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    name: 'data-dictionary',
    components: {},
    data() {
        return {
            navData: [{
                name: '产品标号',
                iconFont: 'iconzhuangtai',
                val: '/admin/dataDictionary/productLabel',
            }, {
                name: '附加型号',
                iconFont: 'iconrenwu',
                val: '/admin/dataDictionary/additionalModels',
            }],
            selectNavIndex: 0,
        };
    },
    watch: {},
    created() {
        this.$router.push('/admin/dataDictionary/productLabel');
    },
    mounted() {},
    computed: {},
    methods: {
        selectNav(index, item) {
            this.selectNavIndex = index;
            this.$router.push(item.val);
        },
    },
    updated() {},
    destroyed() {},
};
</script>

<style lang="stylus" scoped>
.dataDictionary
    width 100%
    padding 0.14rem
    display flex
    .dataDictionaryNav
        width 1.6rem
        height 100%
        background #F4F7FD
        border 2px solid #fff
        padding 0.2rem 0
        li
            height 0.6rem
            font-size 0.18rem
            color #666666
            display flex
            justify-content center
            align-items center
            cursor pointer
            background: #F4F7FD;
            border-radius: 2px;
            border-top: 2px solid #FFFFFF;
            border-bottom: 2px solid #FFFFFF;
            i
                font-size 0.2rem
                margin-right 0.1rem
                color #979EA7
            &.active
                position: relative
                z-index 2
                color #FFFFFF
                border none
                background: linear-gradient(270deg, #55A7FE 0%, #3E79C5 100%);
                box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.4);
                i
                    color #fff
    .dataDictionaryContent
        width calc(100% - 1.6rem)
        height 100%
        padding: 0.14rem
        background: #fff
</style>
